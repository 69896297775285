import { Box, Divider } from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import { useAuthContext } from 'app/auth'
import { TCashboxAvailability } from 'app/codecs/cashbox-availability'
import { Parcel } from 'app/codecs/parcel'
import { TPhoneNumber } from 'app/codecs/phone-number'
import { isAdminRole, isSuperAdminRole, TProfile } from 'app/codecs/user'
import { CashPaymentDialog } from 'components/forms/cash-payment'
import { PARCEL_ARREARS_QUERY_KEY } from 'components/forms/parcel-actions'
import { ParcelRefusalDialog } from 'components/forms/refusal-parcel'
import { ScannerDialog } from 'components/scanner-dialog'
import { DualItemType } from 'components/ui/dual-items'
import { FormDialog } from 'components/ui/form-dialog/form-dialog'
import { useBoolean } from 'hooks/use-boolean'
import { useNotificationContext } from 'hooks/use-notification'
import { Array as ioArray, type } from 'io-ts'
import { Handler } from 'lib/react-handy-types'
import { useQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { MeestVerification } from 'meest-domain/utils'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { v4 as uuidV4 } from 'uuid'

import { ControlledChoice } from '../../form/controlled-choice'
import { FormField } from '../../form/form-field'
import { CheckPaymentNameDialog } from './check-payment-name-dialog'
import { DialogActions } from './dialog-actions'
import { ECheckPaymentDialog } from './echeck-payment-dialog'
import { ParcelDetails } from './parcel-details'
import { PaymentDialog } from './payment-dialog'
import {
  isParcelIssuanceMode,
  isParcelReceptionMode,
  isParcelReturnMode,
  isUnpackOption,
} from './utils'

export type FormMode = 'parcel-reception' | 'parcel-issuance' | 'parcel-return'

export type Sticker = {
  id: string
  main: string
  confirm: string
}

export type StickerFieldValues = Record<string, Array<Sticker>>

export type UnpackFieldValues = Record<string, string | null | undefined>

export type ParcelIdentificationFieldValues = MeestVerification & {
  stickers: StickerFieldValues
  unpackChoices?: UnpackFieldValues
}

type Props = {
  mode: FormMode
  isOpen: boolean
  parcels: Array<Parcel>
  isParcelsLoading: boolean
  isSubmitting: boolean
  onClose: Handler
  onOpen?: Handler
  onRefuseSuccess?: (parcelId: string) => void
  renderParcelDetails: (parcel: Parcel) => Array<DualItemType>
  onSubmit: (values: ParcelIdentificationFieldValues) => void
  closeDialogTitle?: string
  branchId: string
  identificationValues?: MeestVerification | null
  clientPhone?: string | null
  setClientPhone?: Dispatch<SetStateAction<string | null>>
  refreshArrears: () => void
  props?: any
}

export const ParcelActionsFormDialog = ({
  mode,
  isOpen,
  parcels,
  isParcelsLoading,
  isSubmitting,
  onRefuseSuccess,
  closeDialogTitle,
  onOpen,
  onClose,
  renderParcelDetails,
  onSubmit,
  branchId,
  identificationValues,
  clientPhone,
  setClientPhone,
  refreshArrears,
}: Props) => {
  const [hasPrinter, setHasPrinter] = useState(false)
  const [isReceptionDisabled, setReceptionEnabling] = useState(true)
  const [refusalParcelId, setRefusalParcelId] = useState<string | null>(null)

  const checkPayNameDialog = useBoolean()
  const payDialog = useBoolean()
  const cashPaymentDialog = useBoolean()
  const eCheckPaymentDialog = useBoolean()
  const isOlxCodPayment = useBoolean(false)

  const { showSuccessNotification } = useNotificationContext()
  const queryClient = useQueryClient()

  const { t } = useTranslation()
  const { auth } = useAuthContext()
  const isAdmin = isAdminRole(auth.role)

  const $user = useQuery('GET', '/api/pudo/users/me', TProfile)
  const user = $user.data

  const {
    isTrue: isScanOpen,
    setTrue: openScan,
    setFalse: closeScan,
  } = useBoolean()

  const parcelIds = parcels.map(parcel => parcel.parcelId)
  const parcelNumbers = parcels
    .filter(parcel => !parcel.paid)
    .map(parcel => parcel.parcelNumber)

  const { senderPhone, receiverPhone } = parcels[0]

  const payerPhone =
    isParcelReceptionMode(mode) || isParcelReturnMode(mode)
      ? senderPhone
      : receiverPhone

  const $sendPaymentLink = useMutation('POST', '/api/pudo/parcels/payments')

  const $cashBoxAvailability = useQuery(
    'PUT',
    `/api/pudo/branches/${branchId}/cash-box`,
    TCashboxAvailability,
  )

  const stickers = parcels.reduce<StickerFieldValues>(
    (acc, parcel) => ({
      ...acc,
      [parcel.parcelId]: Array.from({ length: parcel.quantity }, () => ({
        id: uuidV4(),
        main: '',
        confirm: '',
      })),
    }),
    {},
  )

  const unpackValues = parcels.reduce<UnpackFieldValues>(
    (acc, parcel) => ({
      ...acc,
      [parcel.parcelId]: isUnpackOption([parcel]) ? null : undefined,
    }),
    {},
  )

  const defaultValues = {
    smsCode: '',
    unpackChoices:
      isParcelIssuanceMode(mode) && isUnpackOption(parcels)
        ? unpackValues
        : undefined,
    stickers,
  }

  const form = useForm<ParcelIdentificationFieldValues>({
    defaultValues,
  })

  const formStickers = form.watch('stickers')

  const formUnpackChoices = form.watch('unpackChoices')

  const identificationType = form.watch('identificationType')

  const isSeveralParcels = parcels.length > 1

  const showPaymentControls = useMemo(
    () => parcels?.some(parcel => parcel.paid === false),
    [parcels],
  )

  const $getPhoneNumber = useMutation(
    'POST',
    '/api/pudo/customers/verification/phones',
    TPhoneNumber,
  )

  const handleScan = (userToken: string) => {
    $getPhoneNumber.mutate(
      {
        body: {
          userToken,
          parcelIds,
          receiver: mode === 'parcel-issuance' ? true : false,
        },
      },
      {
        onSuccess: ({ phoneNumber }) => {
          setClientPhone?.(phoneNumber)
          closeScan()
        },
      },
    )
  }

  useEffect(() => {
    if (identificationType === 'BAR' || identificationType === 'QR') {
      openScan()
    }
  }, [identificationType, openScan])

  const $getPrinterOnBranch = useMutation(
    'GET',
    `/api/pudo/branches/:branchId/printer`,
    type({
      printer: ioArray,
    }),
  )

  /* eslint-disable */
  useEffect(() => {
    $getPrinterOnBranch.mutate(
      { params: { branchId } },
      {
        onSuccess: (data) => {
          setHasPrinter(data?.printer ? !!data.printer[0] : false);
        },
      }
    );
  }, []);
  /* eslint-enable */

  const handleErrors = async (parcelId: SetStateAction<string | null>) => {
    if (form.getValues(`unpackChoices.${parcelId}`) === null) {
      form.setError(
        `unpackChoices.${parcelId}`,
        { type: 'manual' },
        { shouldFocus: false },
      )
    } else {
      setRefusalParcelId(parcelId)
      onClose()
    }
  }

  const handleDialogErrors = async (
    parcelIds: SetStateAction<string | null>[],
  ) => {
    if (form.getValues(`unpackChoices.${parcelIds}`) === null) {
      form.setError(
        `unpackChoices.${parcelIds}`,
        { type: 'manual' },
        { shouldFocus: false },
      )
    } else {
      setRefusalParcelId(parcelIds[0])
      onClose()
    }
  }

  const onSetIdentificationFormValues = () => {
    const identificationFormValues = form.getValues()
    if (identificationValues != null) {
      identificationFormValues.document = identificationValues?.document
      identificationFormValues.identificationType =
        identificationValues?.identificationType
      identificationFormValues.smsCode = identificationValues?.smsCode || ''
    }
    return identificationFormValues
  }

  const filterParcelsByPayer = () => {
    let filteredParcels: Array<Parcel> = []
    if (
      isParcelReceptionMode(mode) ||
      (isParcelIssuanceMode(mode) && identificationValues)
    ) {
      if (isParcelReceptionMode(mode)) {
        filteredParcels = parcels.filter(
          p => p.receiverPay === false && p.paid === false,
        )
      } else if (isParcelIssuanceMode(mode)) {
        filteredParcels = parcels.filter(
          p => p.receiverPay === true && p.paid === false,
        )
      }
    }

    return filteredParcels
  }

  /* eslint-disable */
  useEffect(() => {
    if (filterParcelsByPayer().length > 0) {
      setTimeout(() => {
        checkPayNameDialog.setTrue()
      }, 10)
    }
  }, [])
  /* eslint-enable */

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <FormDialog
        open={isOpen && payDialog.isFalse && cashPaymentDialog.isFalse}
        onClose={onClose}
        closeDialogTitle={closeDialogTitle}
        form={form}
        onSubmit={onSubmit}
        title={
          isParcelReceptionMode(mode)
            ? 'dialog_titles.recept_parcel'
            : isParcelReturnMode(mode)
            ? 'buttons.return_parcel'
            : 'dialog_titles.issue_parcel'
        }
        headAction={
          <LoadingButton
            variant="text"
            size="small"
            sx={{ px: 1 }}
            loading={isParcelsLoading}
            onClick={() =>
              queryClient.invalidateQueries([
                PARCEL_ARREARS_QUERY_KEY,
                ...parcelIds.sort(),
              ])
            }
          >
            {t('buttons.update')}
          </LoadingButton>
        }
        actions={
          <DialogActions
            mode={mode}
            disableReception={
              isParcelReceptionMode(mode) &&
              (isSuperAdminRole(auth.role) ? hasPrinter : user?.printer) &&
              isReceptionDisabled
            }
            clientPhone={clientPhone}
            control={form.control}
            setClientPhone={setClientPhone}
            showPaymentControls={showPaymentControls}
            parcelIds={parcelIds}
            isParcelsLoading={isParcelsLoading}
            isSubmitting={isSubmitting}
            onPayParcels={payDialog.setTrue}
            onRefuseParcel={() => handleDialogErrors(parcelIds)}
            isSeveralParcels={isSeveralParcels}
          />
        }
      >
        {parcels.map((parcel, index) => {
          return (
            <>
              <ParcelDetails
                control={form.control}
                key={parcel.parcelId}
                mode={mode}
                parcel={parcel}
                stickerFields={formStickers[parcel.parcelId]}
                renderParcelDetails={renderParcelDetails}
                setValue={form.setValue}
                isSeveralParcels={isSeveralParcels}
                readyToReception={setReceptionEnabling}
                onRefusalParcel={parcelId => handleErrors(parcelId)}
              >
                <div style={{ marginTop: '20px' }}>
                  {isParcelIssuanceMode(mode) &&
                    (isUnpackOption([parcel]) ? (
                      <>
                        <Divider sx={{ my: 2 }} />
                        {!parcel.international && (
                          <div
                            style={{
                              background: 'rgba(255, 0, 0, 0.05)',
                              color: '#FF0000',
                              borderRadius: '7px',
                              width: '100%',
                              padding: '14px 9px 15px 15px',
                            }}
                          >
                            {t('dialog_confirm.watch_parcel_warn')}
                          </div>
                        )}
                        <FormField>
                          <ControlledChoice
                            required
                            key={parcel.parcelId}
                            control={form.control}
                            name={`unpackChoices.${parcel.parcelId}`}
                            label={t('form_fields.parcel_unpacking')}
                          />
                        </FormField>
                      </>
                    ) : (
                      <Box
                        textAlign={'center'}
                        sx={{
                          background: 'rgba(255, 0, 0, 0.05)',
                          color: '#FF0000',
                          borderRadius: '7px',
                          padding: '14px 9px 15px 15px',
                        }}
                      >
                        {t('form_fields.no_unpack_option')}
                      </Box>
                    ))}
                </div>
              </ParcelDetails>
              {index + 1 !== parcels.length && <Divider sx={{ my: 2 }} />}
            </>
          )
        })}
      </FormDialog>
      <CheckPaymentNameDialog
        mode={mode}
        checkPayNameParcels={filterParcelsByPayer()}
        isOpen={checkPayNameDialog.isTrue}
        closeDialog={checkPayNameDialog.setFalse}
        onClose={() => {
          checkPayNameDialog.setFalse()
          //onClose()
        }}
      />
      <PaymentDialog
        isOpen={payDialog.isTrue}
        onClose={payDialog.setFalse}
        isCashPaymentAvailable={$cashBoxAvailability.data?.cashBox}
        isPosAvailable={user?.pos}
        isOlxCodPayment={isOlxCodPayment.isTrue}
        onSendPaymentLink={() => {
          if (payerPhone) {
            $sendPaymentLink.mutate(
              {
                body: {
                  phoneNumber: payerPhone,
                  parcelIds: parcelIds,
                },
                search: isAdmin ? new URLSearchParams({ branchId }) : undefined,
              },
              {
                onSuccess: () => {
                  payDialog.setFalse()
                  showSuccessNotification(t('notifications.payment_link_sent'))
                },
              },
            )
          }
        }}
        onCashPayment={() => {
          payDialog.setFalse()
          cashPaymentDialog.setTrue()
        }}
        onECheckPayment={() => {
          if (parcelNumbers.length === 1) {
            // eslint-disable-next-line security/detect-non-literal-fs-filename
            window.open(
              `echeckapp://sens?action=openOrder&orderNumber=${parcelNumbers[0]}`,
              '_blank',
            )
          } else eCheckPaymentDialog.setTrue()
        }}
        isSendPaymentLinkLoading={$sendPaymentLink.isLoading}
      />
      <CashPaymentDialog
        isOpen={cashPaymentDialog.isTrue}
        onClose={() => {
          cashPaymentDialog.setFalse()
          onOpen?.()
        }}
        onError={() => {
          cashPaymentDialog.setFalse()
          isOlxCodPayment.setTrue()
          payDialog.setTrue()
          onOpen?.()
        }}
        onSuccess={refreshArrears}
        branchId={branchId}
        parcels={parcels}
        returnRequest={isParcelReturnMode(mode) ? 'IS_RETURN' : 'IS_NOT_RETURN'}
      />
      {refusalParcelId !== null && onRefuseSuccess && (
        <ParcelRefusalDialog
          isOpen={true}
          onClose={() => {
            setRefusalParcelId(null)
            onOpen?.()
          }}
          clientPhone={clientPhone}
          onSuccess={onRefuseSuccess}
          parcelId={refusalParcelId}
          branchId={branchId}
          unpackChoices={formUnpackChoices}
          identificationControl={form.control}
          identificationFormValues={onSetIdentificationFormValues()}
        />
      )}
      <ScannerDialog
        isBarcode={identificationType === 'BAR'}
        open={isScanOpen}
        onClose={closeScan}
        onUpdate={handleScan}
      />
      <ECheckPaymentDialog
        isOpen={eCheckPaymentDialog.isTrue}
        onClose={() => eCheckPaymentDialog.setFalse()}
        parcelNumbers={parcelNumbers}
      />
    </>
  )
}
